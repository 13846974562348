import {
  Flex,
  Link,
  Stack,
  Box,
  Menu,
  MenuButton,
  Text,
  IconButton,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import MenuIcon from "./menu-icon";
import React from "react";

const handleLogout = () => {
  // Clear session token or any other necessary logout actions
  localStorage.removeItem("userInfo");
  // Redirect to the home page
  window.location.href = "/";
};

const LinkText = ({ children, color, ...props }) => {
  const colorValue = color || "white";
  return (
    <Text
      as="span"
      fontSize={["lg", "lg", "md"]}
      fontWeight="bold"
      color={colorValue}
      {...props}
    >
      {children}
    </Text>
  );
};

const PINavigationBar = () => {
  const { isOpen, onOpen } = useDisclosure();
  const btnRef = useRef();
  const isUser = true;
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-evenly"
      wrap="wrap"
      padding="1.5rem"
      w="100%"
      mb={0}
      p={8}
      position="relative"
      bg="black"
      borderTopStyle="solid"
      borderTopColor="red.600"
      borderTopWidth={[0, 0, 2, 2]}
    >
      <Box display={{ base: "block", md: "none" }}>
        <IconButton
          aria-label={isOpen ? "Close" : "Open"}
          variant="ghost"
          onClick={onOpen}
          ref={btnRef}
          icon={<MenuIcon isOpened={isOpen} />}
        />
      </Box>
      <Link href="https://usc.edu" isExternal>
        <Image
          src={process.env.PUBLIC_URL + "/images/usc-logo.png"}
          alt="University of Southern California"
          width="300px"
          height="100px"
          objectFit="contain"
        />
      </Link>

      <Box
        display={{ base: "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
        h={["100vh", "100vh", "auto"]}
      >
        <Stack
          spacing={[10, 10, 16, 16]}
          align="center"
          justify={["center", "space-between", "center", "center"]}
          direction={["column", "column", "row", "row"]}
          pt={[4, 4, 0, 0]}
        >
          <Menu preventOverflow="true">
            {isUser ? (
              <MenuButton
                color="white"
                as="a"
                href="https://cni.usc.edu/clims/queries/newstatistics.php"
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                {" "}
                <LinkText>Find Records</LinkText>
              </MenuButton>
            ) : (
              ""
            )}
            <MenuButton
              color="white"
              as="a"
              href="/calendar"
              style={{
                paddingLeft: 20,
                textDecoration: "none",
                fontSize: "10",
                color: "inherit",
                "&:hover": { textDecoration: "none", color: "inherit" },
              }}
            >
              {" "}
              <LinkText>Calendar</LinkText>
            </MenuButton>
            <MenuButton
              color="white"
              as="a"
              href="/online-new"
              style={{
                paddingLeft: 20,
                textDecoration: "none",
                fontSize: "10",
                color: "inherit",
                "&:hover": { textDecoration: "none", color: "inherit" },
              }}
            >
              {" "}
              <LinkText>Online</LinkText>
            </MenuButton>
            <MenuButton
              color="white"
              as="a"
              href="/profile"
              style={{
                paddingLeft: 20,
                textDecoration: "none",
                fontSize: "10",
                color: "inherit",
                "&:hover": { textDecoration: "none", color: "inherit" },
              }}
            >
              {" "}
              <LinkText>My Profile</LinkText>
            </MenuButton>
            <MenuButton
              color="white"
              as="a"
              onClick={handleLogout}
              style={{
                paddingLeft: 20,
                textDecoration: "none",
                fontSize: "10",
                color: "inherit",
                "&:hover": { textDecoration: "none", color: "inherit" },
              }}
            >
              {" "}
              <LinkText>Logout</LinkText>
            </MenuButton>
          </Menu>

          {/* This is an EXTERNAL link i.e., goes to PHP site. So should not use the LinkItem component */}
          {/* <Link color="white" href="https://cni.usc.edu/clims/CNILogin_new.php">
                  <LinkText>CNI Login</LinkText>
                </Link> */}
        </Stack>
      </Box>
    </Flex>
  );
};

export default PINavigationBar;
