import React from "react";
import {
  Container,
  Text,
  Heading,
  Divider,
  VStack,
  Center,
  Box,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useUserBySSO } from "../../hooks/data/user.js";
import { LoadingSpinner } from "../../components/loading-spinner";
import ErrorCard from "../../components/error-card";

const NewUserTutorial = () => {
  const { user, isLoading, isError } = useUserBySSO();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ErrorCard />;
  }

  const userText = user.join(",");

  const uniqueUserText = [...new Set(userText.split(","))].join(",");

  const elements = uniqueUserText.split(",");

  // Insert a space after every 10 elements
  const elementsWithSpace = [];
  for (let i = 0; i < elements.length; i++) {
    elementsWithSpace.push(elements[i]);
    if ((i + 1) % 10 === 0) {
      elementsWithSpace.push(" ");
    } else {
      elementsWithSpace.push(",");
    }
    if ((i + 1) % 100 === 0) {
      elementsWithSpace.push("----NEXT-----");
    }
  }

  const finalText = elementsWithSpace.join("");
  return (
    <>
      <Helmet>
        <title>Training</title>
      </Helmet>

      <Container maxW="container.lg">
        <VStack spacing={8}>
          <Center>
            <Heading as="h3" size="xl">
              Manage MyGroups
            </Heading>
          </Center>

          <Divider />

          <Box>
            <VStack
              spacing={6}
              align="flex-start"
              w="100%"
              boxShadow="base"
              rounded="md"
              p={4}
            >
              <Text>{finalText}</Text>
            </VStack>
          </Box>
        </VStack>
      </Container>
    </>
  );
};

export default NewUserTutorial;
