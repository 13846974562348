import { Box, Heading, Stack, Center } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

export default function ErrorCard() {
  return (
    <Box
      role="group"
      p={6}
      maxW="330px"
      w="full"
      bg="white"
      boxShadow="md"
      rounded="lg"
      pos="relative"
      zIndex={1}
      minW={["460px", "460px", "700px"]}
    >
      <Box
        rounded="lg"
        pos="relative"
        minH={["200px", "200px", "200px", "200px"]}
        h="100%"
        pb={4}
      >
        <Center>
          <WarningTwoIcon w={[20, 20, 24]} h={[20, 20, 24]} color="red.500" />
        </Center>

        <Stack pt={10} align="center">
          <Heading
            fontSize={["lg", "lg", "2xl", "2xl"]}
            fontWeight={500}
            fontFamily="body"
          >
            Oops! Something went wrong, please reload the page.
          </Heading>
        </Stack>
      </Box>
    </Box>
  );
}

export { ErrorCard };
