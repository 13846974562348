import { Heading, Flex } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const NoMatch = () => (
  <>
    <Helmet>
      <title>Page Not Found - CNI USC</title>
    </Helmet>
    <Flex minH="200px" justifyContent="center" align="center">
      <Heading as="h1" fontSize={{ base: "l", md: "xl", lg: "2xl" }}>
        Oops! The page you're looking for does not exist.
      </Heading>
    </Flex>
  </>
);

export default NoMatch;
