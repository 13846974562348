import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  Text,
  Flex,
} from "@chakra-ui/react";

import { useState } from "react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";

function RecordMenus({ color = "white", classLevel, onClick }) {
  //classLevel = classLevel;
  const [isQueriesOpen, setIsQueriesOpen] = useState(false);

  const handleQueriesToggle = () => {
    setIsQueriesOpen(!isQueriesOpen);
  };
  const handleRecords = () => {
    setIsQueriesOpen(false);
  };

  const LinkText = ({ children, color, ...props }) => {
    const colorValue = color;

    return (
      <Text
        as="span"
        fontSize={["lg", "lg", "md"]}
        fontWeight="bold"
        color={colorValue}
        {...props}
      >
        {children}
      </Text>
    );
  };

  return (
    <Menu preventOverflow="true">
      <MenuButton color="white" onClick={handleRecords}>
        {" "}
        <LinkText color={color}>Records</LinkText>
      </MenuButton>
      <MenuList>
        {classLevel !== 4 && (
          <MenuItem
            as="a"
            href="/records/view-record"
            onClick={onClick}
            style={{
              paddingLeft: 20,
              textDecoration: "none",
              fontSize: "10",
              color: "inherit",
              "&:hover": { textDecoration: "none", color: "inherit" },
            }}
          >
            <LinkText>Find Records</LinkText>
          </MenuItem>
        )}
        {classLevel !== 4 && (
          <MenuItem
            as="a"
            href="/records/add-record"
            onClick={onClick}
            style={{
              paddingLeft: 20,
              textDecoration: "none",
              fontSize: "10",
              color: "inherit",
              "&:hover": { textDecoration: "none", color: "inherit" },
            }}
          >
            <LinkText>Add</LinkText>
          </MenuItem>
        )}
        {classLevel !== 2 && classLevel !== 7 && (
          <MenuGroup
            title={
              <Flex justifyContent="space-between" alignItems="center">
                <Text>Queries</Text>
                {isQueriesOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
              </Flex>
            }
            fontWeight="bold"
            fontSize={["lg", "lg", "md"]}
            direction="rtl"
            onClick={handleQueriesToggle}
            style={{ paddingLeft: 5 }}
          >
            {isQueriesOpen && (
              <>
                {(classLevel === 1 || classLevel === 7) && (
                  <MenuItem
                    as="a"
                    href="/under-construction"
                    onClick={onClick}
                    style={{
                      paddingLeft: 35,
                      textDecoration: "none",
                      fontSize: "5",
                      color: "inherit",
                      "&:hover": { textDecoration: "none", color: "inherit" },
                    }}
                  >
                    Invoices
                  </MenuItem>
                )}

                {(classLevel === 1 || classLevel === 7) && (
                  <MenuItem
                    as="a"
                    href="/under-construction"
                    onClick={onClick}
                    style={{
                      paddingLeft: 35,
                      textDecoration: "none",
                      fontSize: "5",
                      color: "inherit",
                      "&:hover": { textDecoration: "none", color: "inherit" },
                    }}
                  >
                    Users
                  </MenuItem>
                )}
                {(classLevel === 1 || classLevel === 7) && (
                  <MenuItem
                    as="a"
                    href="/under-construction"
                    onClick={onClick}
                    style={{
                      paddingLeft: 35,
                      textDecoration: "none",
                      fontSize: "5",
                      color: "inherit",
                      "&:hover": { textDecoration: "none", color: "inherit" },
                    }}
                  >
                    Customers
                  </MenuItem>
                )}
                {(classLevel === 1 || classLevel === 7) && (
                  <MenuItem
                    as="a"
                    href="/under-construction"
                    onClick={onClick}
                    style={{
                      paddingLeft: 35,
                      textDecoration: "none",
                      fontSize: "5",
                      color: "inherit",
                      "&:hover": { textDecoration: "none", color: "inherit" },
                    }}
                  >
                    Instrument Comment Logs
                  </MenuItem>
                )}
                {(classLevel === 1 || classLevel === 7) && (
                  <MenuItem
                    as="a"
                    href="/under-construction"
                    onClick={onClick}
                    style={{
                      paddingLeft: 35,
                      textDecoration: "none",
                      fontSize: "5",
                      color: "inherit",
                      "&:hover": { textDecoration: "none", color: "inherit" },
                    }}
                  >
                    User Accounts
                  </MenuItem>
                )}

                {(classLevel === 1 || classLevel === 7) && (
                  <MenuItem
                    as="a"
                    href="/under-construction"
                    onClick={onClick}
                    style={{
                      paddingLeft: 35,
                      textDecoration: "none",
                      fontSize: "5",
                      color: "inherit",
                      "&:hover": { textDecoration: "none", color: "inherit" },
                    }}
                  >
                    Generate Invoice
                  </MenuItem>
                )}
                {classLevel === 1 && (
                  <MenuItem
                    as="a"
                    href="/under-construction"
                    onClick={onClick}
                    style={{
                      paddingLeft: 35,
                      textDecoration: "none",
                      fontSize: "5",
                      color: "inherit",
                      "&:hover": { textDecoration: "none", color: "inherit" },
                    }}
                  >
                    User Email
                  </MenuItem>
                )}
              </>
            )}
          </MenuGroup>
        )}

        {(classLevel === 1 || classLevel === 2 || classLevel === 7) && (
          <MenuItem
            as="a"
            href="/instrument-log"
            onClick={onClick}
            style={{
              paddingLeft: 20,
              textDecoration: "none",
              fontSize: "10",
              color: "inherit",
              "&:hover": { textDecoration: "none", color: "inherit" },
            }}
          >
            <LinkText>Instrument Logs</LinkText>
          </MenuItem>
        )}

        {(classLevel === 1 || classLevel === 7) && (
          <MenuItem
            as="a"
            href="/queries/usage-statistics"
            onClick={onClick}
            style={{
              paddingLeft: 20,
              textDecoration: "none",
              fontSize: "10",
              color: "inherit",
              "&:hover": { textDecoration: "none", color: "inherit" },
            }}
          >
            <LinkText>Usage Statistics (%)</LinkText>
          </MenuItem>
        )}

        {classLevel === 1 && (
          <MenuItem
            as="a"
            href="/access"
            onClick={onClick}
            style={{
              paddingLeft: 20,
              textDecoration: "none",
              fontSize: "10",
              color: "inherit",
              "&:hover": { textDecoration: "none", color: "inherit" },
            }}
          >
            <LinkText>Facility Access</LinkText>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}
export default RecordMenus;
