import { Outlet } from "react-router-dom";
import { Grid, GridItem, VStack } from "@chakra-ui/react";

// TODO: Fix with actual layout
export const WelcomeLayout = () => {
  return (
    <>
      <Grid templateRows="auto" gap={8}>
        <GridItem boxSizing="border-box">
          <VStack spacing={0}></VStack>
        </GridItem>
        <GridItem boxSizing="border-box">
          <Outlet />
        </GridItem>
        <GridItem boxSizing="border-box"></GridItem>
      </Grid>
    </>
  );
};

export default WelcomeLayout;
