import useSWR from "swr";

const fetcher = (url) => fetch(url).then((res) => res.json());

const useHomePageImages = () => {
  const url = `${process.env.REACT_APP_API_URL}/home-page-images`;
  const { data, error } = useSWR(url, fetcher);
  //   console.log("Inside custom hook home page image");
  // console.log(url, data);
  return {
    homePageImages: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export { useHomePageImages };
