import { Image, Link } from "@chakra-ui/react";

const CNIMap = () => {
  return (
    <Link href="https://goo.gl/maps/QgcDHfBLrk7zt3PK6" isExternal>
      <Image
        src={process.env.PUBLIC_URL + "/images/cni-map.jpg"}
        alt="Core Center of Excellence in Nano Imaging"
        width={["352px", "352px", "352px", "448px"]}
        height={["220px", "220px", "220px", "280px"]}
        objectFit="contain"
      />
    </Link>
  );
};

export default CNIMap;
