import { Outlet } from "react-router-dom";
import { Heading, Container, Text, Divider, VStack } from "@chakra-ui/react";

const InstrumentLayout = () => {
  return (
    <Container maxW="container.lg">
      <VStack w="100%" spacing={8}>
        <Heading as="h3" size="xl">
          Instrumentation
        </Heading>
        <Divider />
        <Text fontWeight="bold">
          Access to instruments and equipment is contingent upon the competency
          and responsibility of the user and is at the discretion of the CNI
          laboratory manager. CNI encourages the independent use of the
          instruments through approved training. Instrumentation usage and all
          services provided by CNI are subject to a recharge fee. The following
          instruments are included in the Core Center of Excellence in Nano
          Imaging and are located in the MCB building.
        </Text>
        <Divider />
      </VStack>
      <Outlet />
    </Container>
  );
};

export default InstrumentLayout;
export { InstrumentLayout };
