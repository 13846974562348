import useSWR from "swr";

const useFetchInstrumentNames = (UserName) => {
  const params = new URLSearchParams();
  params.append("UserName", UserName);

  const url = `${
    process.env.REACT_APP_API_URL
  }/calendar/fetch-instruments?${params.toString()}`;
  const { data, error } = useSWR(url);
  return {
    instruments: data,
    isLoadingInstruments: !error && !data,
    isErrorInstruments: error,
  };
};

const useCalendarEvents = (InstrumentName, StartTime, EndTime) => {
  const params = new URLSearchParams();
  params.append("InstrumentName", InstrumentName);
  params.append("StartTime", StartTime);
  params.append("EndTime", EndTime);

  const url = `${
    process.env.REACT_APP_API_URL
  }/calendar/fetch-calendar-events?${params.toString()}`;
  const { data, error } = useSWR(url);
  return {
    events: data,
    isLoadingCal: !error && !data,
    isErrorCal: error,
  };
};

const useUserData = (userName) => {
  const params = new URLSearchParams();
  params.append("UserName", userName);

  const url = `${process.env.REACT_APP_API_URL}/calendar/fetch-user-data?UserName=${userName}`;
  const { data, error } = useSWR(url);
  return {
    userData: data,
    isLoadingUserData: !error && !data,
    isErrorUserData: error,
  };
};

export { useFetchInstrumentNames, useCalendarEvents, useUserData };
