import { Menu, MenuButton, MenuList, MenuItem, Text } from "@chakra-ui/react";

function PricingMenus({ color = "white", onClick }) {
  const LinkText = ({ children, color, ...props }) => {
    const colorValue = color;
    return (
      <Text
        as="span"
        fontSize={["lg", "lg", "md"]}
        fontWeight="bold"
        color={colorValue}
        {...props}
      >
        {children}
      </Text>
    );
  };

  return (
    <Menu preventOverflow="true">
      <MenuButton>
        {" "}
        <LinkText color={color}>Pricing</LinkText>
      </MenuButton>
      <MenuList>
        <MenuItem
          as="a"
          href="/clims/create-quotes"
          onClick={onClick}
          style={{
            paddingLeft: 20,
            textDecoration: "none",
            fontSize: "10",
            color: "inherit",
            "&:hover": { textDecoration: "none", color: "inherit" },
          }}
        >
          <LinkText>Create Quotes</LinkText>
        </MenuItem>
        <MenuItem
          as="a"
          href="/clims/price-list"
          onClick={onClick}
          style={{
            paddingLeft: 20,
            textDecoration: "none",
            fontSize: "10",
            color: "inherit",
            "&:hover": { textDecoration: "none", color: "inherit" },
          }}
        >
          <LinkText>Fee Schedule</LinkText>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
export default PricingMenus;
