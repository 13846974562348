import { useState } from "react";
import {
  Stack,
  Container,
  Heading,
  Divider,
  FormControl,
  FormLabel,
  Button,
  VStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  FormHelperText,
  FormErrorMessage,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalOverlay,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverHeader,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { Icon } from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const schemaModal = yup.object().shape({
  Email: yup
    .string()
    .email("Must be a valid email")
    .required("The email is required"),
});
const schema = yup.object().shape({
  Username: yup.string().required("Username is required"),
  Password: yup.string().required("Password is required"),
});

const Login = () => {
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [successPopover, setSuccessPopover] = useState(false);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showPassword, setShowPassword] = useState(false);
  const handleSuccessPopover = () => setSuccessPopover(!successPopover);
  const handleShowClick = () => setShowPassword(!showPassword);
  const openPopover = () => setIsOpenPopover(!isOpenPopover);
  const closePopover = () => {
    handleSuccessPopover();
    setIsOpenPopover(false);
  };
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit: handleSubmitModal,
    register: registerModal,
    formState: { errors: errorsModal, isSubmitting: isSubmittingModal },
    reset: resetModal,
  } = useForm({
    resolver: yupResolver(schemaModal),
  });
  const onSubmit = async (values) => {
    // const result = await executeRecaptcha();
    let finalValues = {
      username: values.Username,
      password: values.Password,
      // captchaToken: result
    };

    await fetch(`/clims/authentication/authentication.php`, {
      method: "POST",
      body: new URLSearchParams(finalValues),
    }).then((response) => {
      if (response.redirected) {
        window.location.href = response.url;
      }
    });
  };
  const onSubmitModal = async (values) => {
    let finalValues = {
      email: values.Email,
    };
    await fetch(`${process.env.REACT_APP_API_URL}/forget-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(finalValues),
    }).then((res) => {
      if (res.status === 201) handleSuccessPopover();
    });
    openPopover();
    resetModal();
  };

  return (
    <>
      <Helmet>
        <title>Login - CNI USC</title>
      </Helmet>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Forgot Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              as="form"
              onSubmit={handleSubmitModal(onSubmitModal)}
              noValidate
            >
              <FormControl isInvalid={errorsModal.Email}>
                <FormLabel>Email:</FormLabel>
                <Input
                  id="email"
                  placeholder="Enter Email ID associated with CNI account"
                  {...registerModal("Email")}
                />
                <FormErrorMessage>
                  {errorsModal.Email && errorsModal.Email.message}
                </FormErrorMessage>
              </FormControl>
              <Popover
                isOpen={isOpenPopover}
                onClose={closePopover}
                placement="bottom"
              >
                <PopoverTrigger>
                  <Button
                    colorScheme="teal"
                    type="submit"
                    mt={8}
                    isLoading={isSubmittingModal}
                  >
                    Submit
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  {successPopover ? (
                    <>
                      <PopoverHeader>Success!</PopoverHeader>
                      <PopoverBody>
                        Credentials are sent if this email exists in our
                        database
                      </PopoverBody>
                    </>
                  ) : (
                    <>
                      <PopoverHeader>Failure!</PopoverHeader>
                      <PopoverBody>Please try Again</PopoverBody>
                    </>
                  )}
                </PopoverContent>
              </Popover>
            </Box>
            <Divider mt={10} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <VStack mb="2" justifyContent="center" alignItems="center">
        <Heading as="h3" size="xl">
          <u>C</u>NI <u>L</u>aboratory <u>I</u>nformation <u>M</u>anagement{" "}
          <u>S</u>ystem
        </Heading>
        <Container maxW="container.lg">
          <Divider mb={4} />
        </Container>
        <Box
          as="form"
          minW={{ base: "90%", md: "468px" }}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Stack
            spacing={4}
            p="1rem"
            backgroundColor="whiteAlpha.900"
            boxShadow="lg"
            rounded="lg"
            minW={{ base: "70%", md: "468px" }}
          >
            <FormControl isInvalid={errors.Username}>
              <FormLabel>Username:</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  children={<Icon as={FaUserAlt} color="gray.300" />}
                />
                <Input
                  id="username"
                  placeholder="Username"
                  {...register("Username")}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.Username && errors.Username.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.Password}>
              <FormLabel>Password:</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  children={<Icon as={FaLock} color="gray.300" />}
                />
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  {...register("Password")}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.Password && errors.Password.message}
              </FormErrorMessage>
              <FormHelperText textAlign="right">
                <Link onClick={onOpen}>forgot password?</Link>
              </FormHelperText>
            </FormControl>
            <Button
              borderRadius={0}
              type="submit"
              variant="solid"
              colorScheme="teal"
              width="full"
              isLoading={isSubmitting}
            >
              Login
            </Button>
          </Stack>
        </Box>
      </VStack>
    </>
  );
};

export default Login;
