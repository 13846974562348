import { Outlet } from "react-router-dom";
import { Grid, GridItem, VStack } from "@chakra-ui/react";
import ClimsNavigationBar from "./clims-navigation-bar";
import AdminNavigationBar from "./admin-clims-navigation-bar";
import PINavigationBar from "./pi-clims-navigation-bar";
import Footer from "./footer";
import { auth } from "../utils/auth";
import React, { useEffect, useState } from "react";

export const ClimsLayout = () => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const curUserInfo = auth();
    setUserInfo(curUserInfo);
  }, []);

  return (
    <>
      <Grid templateRows="auto" gap={8}>
        <GridItem boxSizing="border-box">
          <VStack spacing={0}>
            {/* Admin */}
            {userInfo && userInfo.class === 1 && <AdminNavigationBar />}
            {/* Staff */}
            {userInfo && userInfo.class === 2 && <AdminNavigationBar />}
            {/* Super User */}
            {userInfo && userInfo.class === 3 && <ClimsNavigationBar />}
            {/* User */}
            {userInfo && userInfo.class === 4 && <ClimsNavigationBar />}
            {/* Lab */}
            {userInfo && userInfo.class === 5 && <ClimsNavigationBar />}
            {/* Service Engineer */}
            {userInfo && userInfo.class === 6 && <ClimsNavigationBar />}
            {/* Financial */}
            {userInfo && userInfo.class === 7 && <AdminNavigationBar />}
            {/* PI */}
            {userInfo && userInfo.class === 8 && <PINavigationBar />}
          </VStack>
        </GridItem>
        <GridItem boxSizing="border-box">
          <Outlet />
        </GridItem>
        <GridItem boxSizing="border-box">
          <Footer />
        </GridItem>
      </Grid>
    </>
  );
};

export default ClimsLayout;
