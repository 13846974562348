import { Container, Skeleton, VStack, Box } from "@chakra-ui/react";

const Fallback = () => {
  return (
    <VStack>
      <Container maxW="container.lg">
        <Skeleton>
          <Box h="300px" />
        </Skeleton>
      </Container>
    </VStack>
  );
};

export default Fallback;
