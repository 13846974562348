const fetcher = (...args) => fetch(...args).then((res) => res.json());

const putFetcher = async (url, { arg }) => {
  return fetch(url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(arg),
  }).then((res) => res.json());
};
export default fetcher;
export { fetcher, putFetcher };
