import { Outlet } from "react-router-dom";
import { Grid, GridItem, VStack } from "@chakra-ui/react";
import NavigationBar from "./navigation-bar";
import Footer from "./footer";
// import Header from "./header";

const Layout = ({ backgroundImage }) => {
  // const location = useLocation();
  return (
    <>
      <Grid templateRows="auto" gap={8} pt="100px">
        <GridItem boxSizing="border-box">
          <VStack spacing={0}>
            <NavigationBar />
            {/* {location.pathname !== "/" && ( // Conditionally render Header
              <Header backgroundImage={backgroundImage} />
            )} */}
          </VStack>
        </GridItem>
        <GridItem boxSizing="border-box">
          <Outlet />
        </GridItem>
        <GridItem boxSizing="border-box">
          <Footer />
        </GridItem>
      </Grid>
    </>
  );
};

export default Layout;
