import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/layout";
import Fallback from "./components/fallback";
import InstrumentLayout from "./components/instrument-layout";
import { WelcomeLayout } from "./components/welcome_layout";
import { ClimsLayout } from "./components/clims-layout";

// Home and NoMatch pages are not lazy-loaded since they are the most commonly used pages.
import NoMatch from "./pages/no-match";
import Home from "./pages/home";
import Login from "./pages/login";
import Calendar from "./pages/center-information/calendar";
import MyGroups from "./pages/center-information/my-groups";

// Lazy load the other pages.
const MyScope = lazy(() => import("./pages/center-information/my-scope"));
const Interest = lazy(() => import("./pages/add-interest"));
const Mission = lazy(() => import("./pages/about/mission"));
const Committee = lazy(() => import("./pages/people/committee"));
const Career = lazy(() => import("./pages/about/career"));
const AddDonation = lazy(() => import("./pages/add-donation"));
const FetchDonation = lazy(() => import("./pages/fetch-donation"));
const Donation = lazy(() => import("./pages/main-donation"));
const AvailableInstruments = lazy(() =>
  import("./pages/resources/availability")
);
const Staff = lazy(() => import("./pages/people/staff"));
const Microscope = lazy(() => import("./pages/resources/microscope"));
const Spectrometry = lazy(() => import("./pages/resources/x-ray"));
const VisitUs = lazy(() => import("./pages/about/visit-us"));
const InstrumentPricing = lazy(() => import("./pages/resources/pricing"));
const InstrumentData = lazy(() => import("./pages/resources/instrument-data"));
const EquipmentData = lazy(() => import("./pages/resources/equipment-data.js"));
const NewUser = lazy(() => import("./pages/new-user"));
const SamplePrep = lazy(() =>
  import("./pages/resources/sample-prep-equipment")
);
const Publications = lazy(() => import("./pages/about/publications"));
const PublicationData = lazy(() => import("./pages/about/publication-data"));
const News = lazy(() => import("./pages/about/news"));
const Instrumentation = lazy(() => import("./pages/resources/resources-index"));
const Policy = lazy(() => import("./pages/about/policy"));
const EquipmentDonation = lazy(() =>
  import("./pages/about/equipment-donation")
);
const PiProfile = lazy(() => import("./pages/profiles/pi-profile"));
const NewUserProfile = lazy(() => import("./pages/profiles/new-user-profile"));
const CustomerProfile = lazy(() =>
  import("./pages/profiles/manage-customers/modify-customer")
);
const AddCustomer = lazy(() =>
  import("./pages/profiles/manage-customers/add-customer")
);
const CNICapabilties = lazy(() => import("./pages/resources/cni-capabilities"));
const AddCapabilties = lazy(() =>
  import("./pages/clims/capabilities/add-capabilities")
);
const ManageCapabilties = lazy(() =>
  import("./pages/clims/capabilities/manage-capabilities")
);

const ModifyInstrument = lazy(() =>
  import("./pages/clims/instruments/modify-instrument")
);

const CreatePricingQuotes = lazy(() =>
  import("./pages/clims/pricing/create-quotes")
);
const PriceList = lazy(() => import("./pages/clims/pricing/price-list"));

const AddStaffMember = lazy(() =>
  import("./pages/clims/staff-members/add-staff-member.js")
);
const UpdateStaffMember = lazy(() =>
  import("./pages/clims/staff-members/update-staff-member.js")
);

//Instruments
const Instruments = lazy(() => import("./pages/clims/instruments/instruments"));
const AddInstruments = lazy(() =>
  import("./pages/clims/instruments/add-instrument")
);
const ArchivedInstruments = lazy(() =>
  import("./pages/clims/instruments/archived-instruments")
);
const InstrumentEmailList = lazy(() =>
  import("./pages/clims/instruments/instrument-email-list")
);
const AddInstrumentWebPage = lazy(() =>
  import("./pages/clims/web-page/instruments/add-instrument.js")
);

const UpdateInstrumentWebPage = lazy(() =>
  import("./pages/clims/web-page/instruments/update-instrument.js")
);
const UpdateEquipmentWebPage = lazy(() =>
  import("./pages/clims/web-page/instruments/update-equipment.js")
);
const ArchivedInstrumentsAndEquipment = lazy(() =>
  import("./pages/clims/web-page/instruments/archived-instruments.js")
);
const Online = lazy(() => import("./pages/online.js"));
const TestUser = lazy(() => import("./pages/profiles/testUser"));
const UnderConstruction = lazy(() =>
  import("./pages/profiles/underConstruction")
);
const Access = lazy(() => import("./pages/clims/access"));
const AddNews = lazy(() => import("./pages/clims/add-news"));
const ArchivedNews = lazy(() => import("./pages/clims/archived-news"));
const ManagedNews = lazy(() => import("./pages/clims/manage-news"));
const ModifyAccess = lazy(() => import("./pages/clims/modify-access"));

const ArchiveHome = lazy(() => import("./pages/clims/home/archive-home"));
const ManageHome = lazy(() => import("./pages/clims/home/manage-home"));
const AddHome = lazy(() => import("./pages/clims/home/add-home"));

const HomeImagesUpload = lazy(() =>
  import("./pages/clims/home/home-images-upload")
);

const AddCareer = lazy(() => import("./pages/clims/careers/add-career"));
const ArchivedCareer = lazy(() =>
  import("./pages/clims/careers/archived-career")
);
const ManageCarreer = lazy(() => import("./pages/clims/careers/manage-career"));

const AddPublication = lazy(() =>
  import("./pages/clims/publications/add-publication")
);
const ManagePublication = lazy(() =>
  import("./pages/clims/publications/manage-publication")
);
const PublicationReport = lazy(() =>
  import("./pages/clims/publications/publication-report")
);

const UpdateEquipmentDonation = lazy(() =>
  import("./pages/clims/equipment-donation/update-equipment-donation-guideline")
);
const Email = lazy(() => import("./pages/email/all-email"));
const InstEmail = lazy(() => import("./pages/email/inst-email"));
const InstUser = lazy(() => import("./pages/email/inst-user"));
const AnnualStatusEmail = lazy(() =>
  import("./pages/email/annual-status-email")
);
const FacilityEmail = lazy(() => import("./pages/email/email-facility"));
const PendingUserEmail = lazy(() => import("./pages/email/pending-user-email"));
const AccountVerifyEmail = lazy(() => import("./pages/email/account-verify"));
const NewUserEmail = lazy(() => import("./pages/email/new-user-email"));
const CryoEMEmail = lazy(() => import("./pages/projects/cryo-em-email"));
const HighResEMEmail = lazy(() => import("./pages/projects/highres-em-email"));
const SampleSubmissionEmail = lazy(() =>
  import("./pages/projects/sample-submission-email")
);

const AddDocuments = lazy(() => import("./pages/clims/add-documents"));

const ViewSignedDocuments = lazy(() =>
  import("./pages/clims/view-signed-documents")
);

const MultipleReservation = lazy(() =>
  import("./pages/clims/multiple-reservation")
);

const AddFullDayEvent = lazy(() => import("./pages/clims/add-full-day-event"));

const NewUserManual = lazy(() =>
  import("./pages/center-information/new-user-manual")
);
const NewUserTutorial = lazy(() =>
  import("./pages/center-information/new-user-tutorial")
);
const MyProfile = lazy(() => import("./pages/clims/my-profile"));
const MyProfileYashh = lazy(() => import("./pages/my-profile"));

const ShibLogin = lazy(() => import("./pages/shib-login"));
const ShibSuccess = lazy(() => import("./pages/shib-success"));

const SampleSubmission = lazy(() =>
  import("./pages/projects/sample-submission")
);
const ViewSampleSubmission = lazy(() =>
  import("./pages/projects/view-submission-sample")
);
const HighResEMAccessRequest = lazy(() =>
  import("./pages/projects/highResolutionEM-access-request")
);
const ViewSubmissionHighRes = lazy(() =>
  import("./pages/projects/view-submission-high-res")
);
const CryoEMAccessRequest = lazy(() =>
  import("./pages/projects/cryoEM-access-request")
);
const ViewSubmissionCryo = lazy(() =>
  import("./pages/projects/view-submission-cryo")
);
const ViewSubmissionCryoTemp = lazy(() =>
  import("./pages/projects/view-submission-cryo-temp")
);

const ManageProfile = lazy(() =>
  import("./pages/profiles/manage-users/manage-profile")
);
const ManageCurrentUsers = lazy(() =>
  import("./pages/profiles/manage-users/manage-current-users")
);
const ManageCurrentCustomers = lazy(() =>
  import("./pages/profiles/manage-customers/manage-current-customers")
);

const ManageArchiveCustomers = lazy(() =>
  import("./pages/profiles/manage-customers/manage-archive-customers")
);
const ManageArchivedUsers = lazy(() =>
  import("./pages/profiles/manage-users/manage-archived-users")
);
const NewRequestsUsers = lazy(() =>
  import("./pages/profiles/manage-users/new-requests-users")
);
const NewTrainingRequests = lazy(() =>
  import("./pages/profiles/manage-users/new-training-requests")
);
const AssistanceRequests = lazy(() =>
  import("./pages/profiles/manage-users/assistance-requests")
);
const ViewPermit = lazy(() => import("./pages/clims/view-permit"));
const Permit = lazy(() => import("./pages/clims/permit"));
const ManageManual = lazy(() => import("./pages/clims/manage-manual"));
const ManageCancellation = lazy(() =>
  import("./pages/clims/manage-cancellation.js")
);

const ScheduleUnavailability = lazy(() =>
  import("./pages/clims/schedule-unavailability.js")
);

const UserPolicy = lazy(() => import("./pages/user-pollicy"));

const ViewRecord = lazy(() => import("./pages/records/view-record.js"));
const AddRecord = lazy(() => import("./pages/records/add-record.js"));
const EditRecord = lazy(() => import("./pages/records/edit-record.js"));

const UsageStatistics = lazy(() =>
  import("./pages/queries/usage-statistics.js")
);

const InstrumentLog = lazy(() => import("./pages/queries/instrument-log.js"));

//Service Reports
const ViewServiceReports = lazy(() =>
  import("./pages/clims/service-reports/view-service-reports.js")
);
const AddServiceReports = lazy(() =>
  import("./pages/clims/service-reports/add-service-reports.js")
);

//Departments Routes
const AddDepartment = lazy(() =>
  import("./pages/clims/departments/add-department")
);
const ManageDepartments = lazy(() =>
  import("./pages/clims/departments/manage-departments")
);

//Schools Routes
const AddSchool = lazy(() => import("./pages/clims/schools/add-school"));
const ManageSchool = lazy(() => import("./pages/clims/schools/manage-school"));

const UpdateUserStatus = lazy(() =>
  import("./pages/authentication/update-user-status")
);

// Committee Members Routes
const AddCommitteeMember = lazy(() =>
  import("./pages/clims/committee-members/add-committee-member.js")
);
const EditCommitteeMember = lazy(() =>
  import("./pages/clims/committee-members/edit-committee-member.js")
);

// Delete Access Page
const ManageAccess = lazy(() =>
  import("./pages/profiles/manage-users/manage-access")
);

const ManageTutorial = lazy(() => import("./pages/clims/manage-tutorial"));

export function AllRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about">
          <Route
            path="news"
            element={
              <Suspense fallback={<Fallback />}>
                <News />
              </Suspense>
            }
          />

          <Route
            path="mission"
            element={
              <Suspense fallback={<Fallback />}>
                <Mission />
              </Suspense>
            }
          />
          <Route
            path="career"
            element={
              <Suspense fallback={<Fallback />}>
                <Career />
              </Suspense>
            }
          />
          <Route
            path="visit-us"
            element={
              <Suspense fallback={<Fallback />}>
                <VisitUs />
              </Suspense>
            }
          />
          <Route
            path="publications"
            element={
              <Suspense fallback={<Fallback />}>
                <Publications />
              </Suspense>
            }
          />
          <Route
            path="policy"
            element={
              <Suspense fallback={<Fallback />}>
                <Policy />
              </Suspense>
            }
          />
          <Route
            path="equipment-donation"
            element={
              <Suspense fallback={<Fallback />}>
                <EquipmentDonation />
              </Suspense>
            }
          />
        </Route>
        <Route path="people">
          <Route
            path="committee"
            element={
              <Suspense fallback={<Fallback />}>
                <Committee />
              </Suspense>
            }
          />
          <Route
            path="staff"
            element={
              <Suspense fallback={<Fallback />}>
                <Staff />
              </Suspense>
            }
          />
        </Route>

        <Route path="resources">
          <Route
            index
            element={
              <Suspense fallback={<Fallback />}>
                <Instrumentation />
              </Suspense>
            }
          />
          <Route element={<InstrumentLayout />}>
            <Route
              path="microscope"
              element={
                <Suspense fallback={<Fallback />}>
                  <Microscope />
                </Suspense>
              }
            />
            <Route
              path="x-ray"
              element={
                <Suspense fallback={<Fallback />}>
                  <Spectrometry />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="availability"
            element={
              <Suspense fallback={<Fallback />}>
                <AvailableInstruments />
              </Suspense>
            }
          />
          <Route
            path="sample-preparation"
            element={
              <Suspense fallback={<Fallback />}>
                <SamplePrep />
              </Suspense>
            }
          />
          <Route
            path="pricing"
            element={
              <Suspense fallback={<Fallback />}>
                <InstrumentPricing />
              </Suspense>
            }
          />
          <Route
            path="cni-services"
            element={
              <Suspense fallback={<Fallback />}>
                <CNICapabilties />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="new-login"
          element={
            <Suspense fallback={<Fallback />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="login"
          element={
            <Suspense fallback={<Fallback />}>
              <ShibLogin />
            </Suspense>
          }
        />
        <Route
          path="update-user-status/:username/:status"
          element={
            <Suspense fallback={<Fallback />}>
              <UpdateUserStatus />
            </Suspense>
          }
        />

        {/* 404 Not found */}
        <Route path="*" element={<NoMatch />} />
        <Route path="/shib-success" element={<ShibSuccess />} />
      </Route>
      {/* The route below needs to change the layout a bit. It pulls the header image from the DB instead of local URL. Hence does not use layout component  */}
      <Route
        path="resources/:id"
        element={
          <Suspense fallback={<Fallback />}>
            <InstrumentData />
          </Suspense>
        }
      />
      <Route
        path="/about/publications/:id"
        element={
          <Suspense fallback={<Fallback />}>
            <PublicationData />
          </Suspense>
        }
      />
      <Route
        path="resources/samplePrep/:id"
        element={
          <Suspense fallback={<Fallback />}>
            <EquipmentData />
          </Suspense>
        }
      />
      <Route
        path="new-user"
        element={
          <Suspense fallback={<Fallback />}>
            <NewUser />
          </Suspense>
        }
      />

      {/* TODO: CLIMS layout.  component needs to be fixed with actual layout later*/}
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="user-policy"
          element={
            <Suspense fallback={<Fallback />}>
              <UserPolicy />
            </Suspense>
          }
        />
        <Route
          path="instruments/instruments"
          element={
            <Suspense fallback={<Fallback />}>
              <Instruments />
            </Suspense>
          }
        />
        <Route
          path="instruments/modify-instrument/:instrumentNo"
          element={
            <Suspense fallback={<Fallback />}>
              <ModifyInstrument />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="/home-images-upload"
          element={
            <Suspense fallback={<Fallback />}>
              <HomeImagesUpload />
            </Suspense>
          }
        />
        <Route
          path="/add-instrument"
          element={
            <Suspense fallback={<Fallback />}>
              <AddInstrumentWebPage />
            </Suspense>
          }
        />
        <Route
          path="/update-instrument"
          element={
            <Suspense fallback={<Fallback />}>
              <UpdateInstrumentWebPage />
            </Suspense>
          }
        />
        <Route
          path="/update-equipment"
          element={
            <Suspense fallback={<Fallback />}>
              <UpdateEquipmentWebPage />
            </Suspense>
          }
        />
        <Route
          path="/archived-instruments"
          element={
            <Suspense fallback={<Fallback />}>
              <ArchivedInstrumentsAndEquipment />
            </Suspense>
          }
        />
        <Route
          path="instruments/add-instrument"
          element={
            <Suspense fallback={<Fallback />}>
              <AddInstruments />
            </Suspense>
          }
        />
        <Route
          path="instruments/archived-instruments"
          element={
            <Suspense fallback={<Fallback />}>
              <ArchivedInstruments />
            </Suspense>
          }
        />
      </Route>
      <Route path="training" element={<ClimsLayout />}>
        <Route
          path="view-permit"
          element={
            <Suspense fallback={<Fallback />}>
              <ViewPermit />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="online"
          element={
            <Suspense fallback={<Fallback />}>
              <Online />
            </Suspense>
          }
        />
      </Route>
      <Route path="/profiles" element={<WelcomeLayout />}>
        <Route
          path="test-user"
          element={
            <Suspense fallback={<Fallback />}>
              <TestUser />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="under-construction"
          element={
            <Suspense fallback={<Fallback />}>
              <UnderConstruction />
            </Suspense>
          }
        />
      </Route>
      <Route path="clims" element={<ClimsLayout />}>
        <Route
          path="my-profile"
          element={
            <Suspense fallback={<Fallback />}>
              <MyProfileYashh />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="access"
          element={
            <Suspense fallback={<Fallback />}>
              <Access />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="modify-access"
          element={
            <Suspense fallback={<Fallback />}>
              <ModifyAccess />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="email"
          element={
            <Suspense fallback={<Fallback />}>
              <Email />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="email-facility"
          element={
            <Suspense fallback={<Fallback />}>
              <FacilityEmail />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="manage-access/:username"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageAccess />
            </Suspense>
          }
        />
      </Route>

      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="email-inst-info"
          element={
            <Suspense fallback={<Fallback />}>
              <InstEmail />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="inst-user"
          element={
            <Suspense fallback={<Fallback />}>
              <InstUser />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="email-annual-status"
          element={
            <Suspense fallback={<Fallback />}>
              <AnnualStatusEmail />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="account-verify"
          element={
            <Suspense fallback={<Fallback />}>
              <AccountVerifyEmail />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="email-pending-user"
          element={
            <Suspense fallback={<Fallback />}>
              <PendingUserEmail />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="new-user-email"
          element={
            <Suspense fallback={<Fallback />}>
              <NewUserEmail />
            </Suspense>
          }
        />
      </Route>
      <Route path="clims" element={<ClimsLayout />}>
        <Route
          path="add-news"
          element={
            <Suspense fallback={<Fallback />}>
              <AddNews />
            </Suspense>
          }
        />
      </Route>
      <Route path="clims" element={<ClimsLayout />}>
        <Route
          path="archived-news"
          element={
            <Suspense fallback={<Fallback />}>
              <ArchivedNews />
            </Suspense>
          }
        />
      </Route>
      <Route path="clims" element={<ClimsLayout />}>
        <Route
          path="manage-news"
          element={
            <Suspense fallback={<Fallback />}>
              <ManagedNews />
            </Suspense>
          }
        />
      </Route>

      <Route path="clims" element={<ClimsLayout />}>
        <Route
          path="add-career"
          element={
            <Suspense fallback={<Fallback />}>
              <AddCareer />
            </Suspense>
          }
        />
      </Route>
      <Route path="clims" element={<ClimsLayout />}>
        <Route
          path="archived-career"
          element={
            <Suspense fallback={<Fallback />}>
              <ArchivedCareer />
            </Suspense>
          }
        />
      </Route>
      <Route path="clims" element={<ClimsLayout />}>
        <Route
          path="manage-career"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageCarreer />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="publications/add-publication"
          element={
            <Suspense fallback={<Fallback />}>
              <AddPublication />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="publications/manage-publication"
          element={
            <Suspense fallback={<Fallback />}>
              <ManagePublication />
            </Suspense>
          }
        />
        <Route
          path="publications/publication-report"
          element={
            <Suspense fallback={<Fallback />}>
              <PublicationReport />
            </Suspense>
          }
        />
      </Route>
      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="add-capabilities"
          element={
            <Suspense fallback={<Fallback />}>
              <AddCapabilties />
            </Suspense>
          }
        />
      </Route>
      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="manage-capabilities"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageCapabilties />
            </Suspense>
          }
        />
      </Route>
      <Route path="clims" element={<ClimsLayout />}>
        <Route
          path="create-quotes"
          element={
            <Suspense fallback={<Fallback />}>
              <CreatePricingQuotes />
            </Suspense>
          }
        />
      </Route>
      <Route path="clims" element={<ClimsLayout />}>
        <Route
          path="price-list"
          element={
            <Suspense fallback={<Fallback />}>
              <PriceList />
            </Suspense>
          }
        />
      </Route>
      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="update-equipment-donation"
          element={
            <Suspense fallback={<Fallback />}>
              <UpdateEquipmentDonation />
            </Suspense>
          }
        />
      </Route>

      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="add-documents"
          element={
            <Suspense fallback={<Fallback />}>
              <AddDocuments />
            </Suspense>
          }
        />
      </Route>

      <Route path="clims" element={<ClimsLayout />}>
        <Route
          path="view-signed-documents"
          element={
            <Suspense fallback={<Fallback />}>
              <ViewSignedDocuments />
            </Suspense>
          }
        ></Route>
      </Route>

      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="multiple-reservation"
          element={
            <Suspense fallback={<Fallback />}>
              <MultipleReservation />
            </Suspense>
          }
        ></Route>
      </Route>

      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="add-full-day-event"
          element={
            <Suspense fallback={<Fallback />}>
              <AddFullDayEvent />
            </Suspense>
          }
        ></Route>
      </Route>

      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="my-profile"
          element={
            <Suspense fallback={<Fallback />}>
              <MyProfile />
            </Suspense>
          }
        ></Route>
      </Route>

      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="calendar"
          element={
            <Suspense fallback={<Fallback />}>
              <Calendar />
            </Suspense>
          }
        />
      </Route>

      <Route path="/center-info" element={<ClimsLayout />}>
        <Route
          path="new-user-manual"
          element={
            <Suspense fallback={<Fallback />}>
              <NewUserManual />
            </Suspense>
          }
        />
      </Route>

      <Route path="/center-info" element={<ClimsLayout />}>
        <Route
          path="new-user-tutorial"
          element={
            <Suspense fallback={<Fallback />}>
              <NewUserTutorial />
            </Suspense>
          }
        />
      </Route>
      <Route path="clims" element={<ClimsLayout />}>
        <Route
          path="archive-home"
          element={
            <Suspense fallback={<Fallback />}>
              <ArchiveHome />
            </Suspense>
          }
        />
      </Route>
      <Route path="clims" element={<ClimsLayout />}>
        <Route
          path="manage-home"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageHome />
            </Suspense>
          }
        />
      </Route>
      <Route path="clims" element={<ClimsLayout />}>
        <Route
          path="add-home"
          element={
            <Suspense fallback={<Fallback />}>
              <AddHome />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="profile"
          element={
            <Suspense fallback={<Fallback />}>
              <PiProfile />
            </Suspense>
          }
        />
      </Route>

      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="new-user-profile/:profile_id/:profile_username"
          element={
            <Suspense fallback={<Fallback />}>
              <NewUserProfile />
            </Suspense>
          }
        />
      </Route>

      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="modify-customer"
          element={
            <Suspense fallback={<Fallback />}>
              <CustomerProfile />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="add-customer"
          element={
            <Suspense fallback={<Fallback />}>
              <AddCustomer />
            </Suspense>
          }
        />
      </Route>
      <Route path="/center-info" element={<ClimsLayout />}>
        <Route
          path="my-scope"
          element={
            <Suspense fallback={<Fallback />}>
              <MyScope />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="add-donation"
          element={
            <Suspense fallback={<Fallback />}>
              <AddDonation />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="fetch-donation"
          element={
            <Suspense fallback={<Fallback />}>
              <FetchDonation />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="donation"
          element={
            <Suspense fallback={<Fallback />}>
              <Donation />
            </Suspense>
          }
        />
      </Route>
      <Route path="/my-groups" element={<ClimsLayout />}>
        <Route
          path="manage"
          element={
            <Suspense fallback={<Fallback />}>
              <MyGroups />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="add-interest"
          element={
            <Suspense fallback={<Fallback />}>
              <Interest />
            </Suspense>
          }
        />
      </Route>
      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="manage-profile/:username"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageProfile />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="manage-current-users"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageCurrentUsers />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="manage-current-customers"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageCurrentCustomers />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="manage-archive-customers"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageArchiveCustomers />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="manage-archived-users"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageArchivedUsers />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="new-requests-users"
          element={
            <Suspense fallback={<Fallback />}>
              <NewRequestsUsers />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="new-training-requests"
          element={
            <Suspense fallback={<Fallback />}>
              <NewTrainingRequests />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="assistance-requests"
          element={
            <Suspense fallback={<Fallback />}>
              <AssistanceRequests />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="permit"
          element={
            <Suspense fallback={<Fallback />}>
              <Permit />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="manage-manual"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageManual />
            </Suspense>
          }
        ></Route>
        <Route
          path="manage-tutorial"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageTutorial />
            </Suspense>
          }
        ></Route>
      </Route>

      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="manage-cancellation"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageCancellation />
            </Suspense>
          }
        ></Route>
      </Route>

      <Route path="instruments" element={<ClimsLayout />}>
        <Route
          path="instrument-email-list"
          element={
            <Suspense fallback={<Fallback />}>
              <InstrumentEmailList />
            </Suspense>
          }
        ></Route>
      </Route>

      <Route path="/manage" element={<ClimsLayout />}>
        <Route
          path="schedule-unavailability"
          element={
            <Suspense fallback={<Fallback />}>
              <ScheduleUnavailability />
            </Suspense>
          }
        ></Route>
      </Route>

      <Route path="staff-members" element={<ClimsLayout />}>
        <Route
          path="add-staff-member"
          element={
            <Suspense fallback={<Fallback />}>
              {/* change the below to point to add-staff memmber page */}
              {/* <ViewServiceReports />  */}
              <AddStaffMember />
            </Suspense>
          }
        ></Route>
        <Route
          path="update-staff-member"
          element={
            <Suspense fallback={<Fallback />}>
              {/* change the below to point to add-staff memmber page */}
              {/* <ViewServiceReports />  */}
              <UpdateStaffMember />
            </Suspense>
          }
        ></Route>
      </Route>

      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="add-committee-member"
          element={
            <Suspense fallback={<Fallback />}>
              <AddCommitteeMember />
            </Suspense>
          }
        ></Route>
      </Route>

      <Route path="manage" element={<ClimsLayout />}>
        <Route
          path="edit-committee-member"
          element={
            <Suspense fallback={<Fallback />}>
              <EditCommitteeMember />
            </Suspense>
          }
        ></Route>
      </Route>

      <Route path="instruments" element={<ClimsLayout />}>
        <Route
          path="view-service-reports"
          element={
            <Suspense fallback={<Fallback />}>
              <ViewServiceReports />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="instruments" element={<ClimsLayout />}>
        <Route
          path="add-service-reports"
          element={
            <Suspense fallback={<Fallback />}>
              <AddServiceReports />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="departments" element={<ClimsLayout />}>
        <Route
          path="add-department"
          element={
            <Suspense fallback={<Fallback />}>
              <AddDepartment />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="departments" element={<ClimsLayout />}>
        <Route
          path="manage-departments"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageDepartments />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="schools" element={<ClimsLayout />}>
        <Route
          path="add-school"
          element={
            <Suspense fallback={<Fallback />}>
              <AddSchool />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path="schools" element={<ClimsLayout />}>
        <Route
          path="manage-school"
          element={
            <Suspense fallback={<Fallback />}>
              <ManageSchool />
            </Suspense>
          }
        ></Route>
      </Route>

      <Route path="records" element={<ClimsLayout />}>
        <Route
          path="view-record"
          element={
            <Suspense fallback={<Fallback />}>
              <ViewRecord />
            </Suspense>
          }
        />
        <Route
          path="add-record"
          element={
            <Suspense fallback={<Fallback />}>
              <AddRecord />
            </Suspense>
          }
        />
        <Route
          path="edit-record/:recordId"
          element={
            <Suspense fallback={<Fallback />}>
              <EditRecord />
            </Suspense>
          }
        />
      </Route>
      <Route path="queries" element={<ClimsLayout />}>
        <Route
          path="usage-statistics"
          element={
            <Suspense fallback={<Fallback />}>
              <UsageStatistics />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="instrument-log"
          element={
            <Suspense fallback={<Fallback />}>
              <InstrumentLog />
            </Suspense>
          }
        />
      </Route>
      <Route path="/" element={<ClimsLayout />}>
        <Route
          path="update-user-status/:username/:status"
          element={
            <Suspense fallback={<Fallback />}>
              <UpdateUserStatus />
            </Suspense>
          }
        />
      </Route>

      <Route path="/projects" element={<ClimsLayout />}>
        {/* Sample Submission */}
        <Route
          path="sample-submission"
          element={
            <Suspense fallback={<Fallback />}>
              <SampleSubmission />
            </Suspense>
          }
        />
        <Route
          path="view-sample-submission"
          element={
            <Suspense fallback={<Fallback />}>
              <ViewSampleSubmission />
            </Suspense>
          }
        />
        <Route
          path="sample-submission-email"
          element={
            <Suspense fallback={<Fallback />}>
              <SampleSubmissionEmail />
            </Suspense>
          }
        />

        {/* High Resolution Access */}
        <Route
          path="highres-em-access-request"
          element={
            <Suspense fallback={<Fallback />}>
              <HighResEMAccessRequest />
            </Suspense>
          }
        />
        <Route
          path="view-submission-high-res"
          element={
            <Suspense fallback={<Fallback />}>
              <ViewSubmissionHighRes />
            </Suspense>
          }
        />
        <Route
          path="highres-em-email"
          element={
            <Suspense fallback={<Fallback />}>
              <HighResEMEmail />
            </Suspense>
          }
        />

        {/* Cryo EM Access */}
        <Route
          path="cryo-em-access-request"
          element={
            <Suspense fallback={<Fallback />}>
              <CryoEMAccessRequest />
            </Suspense>
          }
        />
        <Route
          path="view-submission-cryo-em"
          element={
            <Suspense fallback={<Fallback />}>
              <ViewSubmissionCryo />
            </Suspense>
          }
        />
        <Route
          path="cryo-em-email"
          element={
            <Suspense fallback={<Fallback />}>
              <CryoEMEmail />
            </Suspense>
          }
        />
      </Route>

      {/* Adding these duplicate routes to allow access to people without CLIMS account */}
      <Route path="/" element={<Layout />}>
        <Route
          path="sample-submission"
          element={
            <Suspense fallback={<Fallback />}>
              <SampleSubmission />
            </Suspense>
          }
        />
        <Route
          path="highres-em-access-request"
          element={
            <Suspense fallback={<Fallback />}>
              <HighResEMAccessRequest />
            </Suspense>
          }
        />
        <Route
          path="cryo-em-access-request"
          element={
            <Suspense fallback={<Fallback />}>
              <CryoEMAccessRequest />
            </Suspense>
          }
        />
        <Route
          path="view-submission-cryo-em-temp"
          element={
            <Suspense fallback={<Fallback />}>
              <ViewSubmissionCryoTemp />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}
