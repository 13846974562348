import {
  Link,
  Heading,
  Box,
  Image,
  Stack,
  Center,
  Text,
  Button,
} from "@chakra-ui/react";
import CNIAddress from "./cni-address";
import CNIMap from "./cni-map";
import { Link as RouterLink } from "react-router-dom";

const ViterbiDornsifeLogo = () => (
  <Stack direction="column">
    <Link
      href="https://usc.edu"
      width={["210px", "210px", "180px", "259px"]}
      height={["70px", "70px", "60px", "86px"]}
      mb={[2, 2, 5, 5]}
      mt={[2, 2, 8, 8]}
      isExternal
    >
      <Image
        src={process.env.PUBLIC_URL + "/images/viterbi-logo.png"}
        alt="University of Southern California"
        objectFit="contain"
      />
    </Link>
    <Link
      href="https://usc.edu"
      p={2}
      width={["350px", "350px", "180px", "350px"]}
      height={["86px", "86px", "60px", "86px"]}
      isExternal
    >
      <Image
        src={process.env.PUBLIC_URL + "/images/dornsife-logo.png"}
        alt="University of Southern California"
        objectFit="contain"
        alignItems="center"
      />
    </Link>
  </Stack>
);

const LinkButton = ({ to, children, color = "white", onClick }) => {
  return (
    <Link as={RouterLink} to={to} onClick={onClick}>
      <Button
        // colorScheme='teal'
        variant="link"
        m={["2", "2", "2", "2"]}
      >
        <Text color="rgb(245, 250, 255)" fontSize={["xs", "xs", "xs", "xs"]}>
          {children}
        </Text>
      </Button>
    </Link>
  );
};

const FooterMenu = () => (
  <Center bg="rgb(33,33,33)" minH="40px" align="center">
    <Stack
      direction={["column", "column", "column", "row"]}
      justify="space-evenly"
    >
      <Box>
        <LinkButton to="/">Home</LinkButton>
        <LinkButton to="/about/mission">Mission</LinkButton>
        <LinkButton to="/resources">Resources</LinkButton>
        <LinkButton to="/people/staff">Professional Staff</LinkButton>
      </Box>
      <Box>
        <LinkButton to="/people/committee">Advisory Committee</LinkButton>
        <LinkButton to="/about/policy">Policy</LinkButton>
        <LinkButton to="/about/career">Career</LinkButton>
        <LinkButton to="/about/visit-us">Visit Us</LinkButton>
      </Box>
      <Box>
        <LinkButton to="/about/news">News</LinkButton>
        <LinkButton to="/">
          &copy; {new Date().getFullYear()} University of Southern California
        </LinkButton>
      </Box>
    </Stack>
  </Center>
);

export function Footer() {
  return (
    <>
      <Stack
        spacing={["0px", "0px", "200px", "200px"]}
        direction={["column", "column", "row", "row"]}
        bg="rgb(33, 33, 33)"
        align="center"
        pt="10px"
        pb="10px"
        // justify="space-evenly"
      >
        <Box>
          <Heading
            pl={["0px", "0px", "50px", "130px"]}
            fontSize="4xl"
            color="rgb(255, 255, 255)"
          >
            CNI
          </Heading>
        </Box>
        <Box
          display={{
            base: "none",
            md: "block",
          }}
        >
          <Text fontSize="sm" color="rgb(245, 250, 255)">
            CNI provides training and scientific support for all users at USC,
            as well services to industry and other external entities.
          </Text>
        </Box>
      </Stack>
      <Stack
        bg="rgb(245, 250, 255)"
        direction={{
          base: "column",
          md: "row",
        }}
        p={2}
        justify="space-evenly"
      >
        <CNIMap />
        <CNIAddress />
        <ViterbiDornsifeLogo />
      </Stack>
      <Box display={{ base: "100%", md: "auto" }}>
        <FooterMenu />
      </Box>
    </>
  );
}

export default Footer;
