import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";

function TrainingMenus({ color = "white", onClick }) {
  const [isCenterInformationOpen, setIsCenterInformationOpen] = useState(false);

  const handleCenterInformationToggle = () => {
    setIsCenterInformationOpen(!isCenterInformationOpen);
  };
  const handleRecords = () => {
    setIsCenterInformationOpen(false);
  };

  const LinkText = ({ children, color, ...props }) => {
    const colorValue = color;
    return (
      <Text
        as="span"
        fontSize={["lg", "lg", "md"]}
        fontWeight="bold"
        color={colorValue}
        {...props}
      >
        {children}
      </Text>
    );
  };

  return (
    <Menu preventOverflow="true">
      <MenuButton onClick={handleRecords}>
        {" "}
        <LinkText color={color}>Training</LinkText>
      </MenuButton>
      <MenuList>
        <MenuItem
          as="a"
          href="/user-policy"
          onClick={onClick}
          style={{
            paddingLeft: 20,
            textDecoration: "none",
            fontSize: "10",
            color: "inherit",
            "&:hover": { textDecoration: "none", color: "inherit" },
          }}
        >
          <LinkText>Policy</LinkText>
        </MenuItem>
        <MenuItem
          as="a"
          href="/training/view-permit"
          onClick={onClick}
          style={{
            paddingLeft: 20,
            textDecoration: "none",
            fontSize: "10",
            color: "inherit",
            "&:hover": { textDecoration: "none", color: "inherit" },
          }}
        >
          <LinkText>Permit</LinkText>
        </MenuItem>
        <MenuItem
          as="a"
          href="/center-info/new-user-manual"
          onClick={onClick}
          style={{
            paddingLeft: 20,
            textDecoration: "none",
            fontSize: "10",
            color: "inherit",
            "&:hover": { textDecoration: "none", color: "inherit" },
          }}
        >
          <LinkText>Manual</LinkText>
        </MenuItem>

        <MenuGroup
          title="Center Information"
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          direction="rtl"
          onClick={handleCenterInformationToggle}
          style={{ paddingLeft: 5 }}
        >
          {isCenterInformationOpen && (
            <>
              <MenuItem
                as="a"
                href="/center-info/new-user-tutorial"
                onClick={onClick}
                style={{
                  paddingLeft: 35,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Tutorial
              </MenuItem>
              <MenuItem
                as="a"
                href="/center-info/my-scope"
                onClick={onClick}
                style={{
                  paddingLeft: 35,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                MyScope
              </MenuItem>
            </>
          )}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
export default TrainingMenus;
