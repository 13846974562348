import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { useEffect } from "react";
import {
  Box,
  Container,
  Flex,
  Heading,
  Button,
  Center,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Global } from "@emotion/react";
import { Parallax } from "../components/parallax";
import { LoadingSpinner } from "../components/loading-spinner";
import { ErrorCard } from "../components/error-card";
import { useHomePageImages } from "../hooks/data/home-page-images";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const MotionBox = motion(Box);

const CarouselComponent = ({ images }) => (
  <Box width="100%" maxWidth="100vw" overflow="hidden" mx="auto">
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlay
      autoPlaySpeed={3000}
      centerMode={false}
      className=""
      containerClass="carousel-container"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 3,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1,
        },
      }}
      showDots
      sliderClass=""
      swipeable
    >
      {images.map((image, index) => (
        <Box key={index} p={2}>
          <Box
            bgImage={`url(${image})`}
            bgSize="cover"
            bgPos="center"
            h="300px"
            borderRadius="md"
          />
        </Box>
      ))}
    </Carousel>
  </Box>
);

const SectionButton = ({ children, link }) => (
  <Button
    as={RouterLink}
    to={link}
    colorScheme="gray"
    variant="solid"
    m={2}
    size="lg"
    width={{ base: "100%", md: "auto" }}
  >
    {children}
  </Button>
);

function Home() {
  const { homePageImages, isLoading, isError } = useHomePageImages();

  useEffect(() => {
    async function getUserInfo() {
      const response = await fetch("/.auth/me");
      const payload = await response.json();
      const givenNameClaim = payload.clientPrincipal.claims.find(
        (claim) =>
          claim.typ ===
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"
      );
      const givenName = givenNameClaim ? givenNameClaim.val : null;
      window.location.href = `https://cni.usc.edu/profiles/test-user?netid=${givenName}`;
      return givenName;
    }

    if (
      window.location.href ===
      "https://green-sand-0f69b2510.1.azurestaticapps.net/"
    ) {
      getUserInfo().then((userInfo) => {
        if (userInfo) {
          console.log(userInfo);
        }
      });
    }
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <Container maxW="container.lg">
        <Center>
          <ErrorCard />
        </Center>
      </Container>
    );
  }

  const backgroundImage = homePageImages.find(
    (image) => image.imageType === "background"
  );
  const carouselImages = homePageImages.filter(
    (image) => image.imageType === "carousel"
  );

  return (
    <>
      <Helmet>
        <title>Home - CNI USC</title>
      </Helmet>

      <Global
        styles={{
          body: {
            backgroundImage: `url(${
              backgroundImage ? backgroundImage.imageLocation : ""
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          },
        }}
      />
      <Parallax offset={20} clampFinal>
        <Box position="relative" py={10} zIndex={2} minH="65vh">
          <Container
            maxW={{
              base: "container.sm",
              md: "container.md",
              lg: "container.lg",
              xl: "container.xl",
            }}
          >
            <Heading
              as="h1"
              size="2xl"
              textAlign="center"
              mb={10}
              color="white"
            >
              Core Center of Excellence in Nano Imaging
            </Heading>

            <CarouselComponent
              images={carouselImages.map((img) => img.imageLocation)}
            />
          </Container>
        </Box>

        <Container
          maxW={{
            base: "container.sm",
            md: "container.md",
            lg: "container.lg",
            xl: "container.xl",
          }}
        >
          <Flex wrap="wrap" justify="center">
            <SectionButton link="/about/mission">
              Mission Statement
            </SectionButton>
            <SectionButton link="/resources">Resources</SectionButton>
            <SectionButton link="/people/staff">
              Professional Staff
            </SectionButton>
            <SectionButton link="/people/committee">
              Advisory Committee
            </SectionButton>
            <SectionButton link="/about/publications">
              Publications
            </SectionButton>
            <SectionButton link="/about/visit-us">Visit Us</SectionButton>
          </Flex>
        </Container>
      </Parallax>
    </>
  );
}

export default Home;
