import useSWR from "swr";

const useEmailList = () => {
  const url = `${process.env.REACT_APP_API_URL}/email-list`;

  const { data, error } = useSWR(url);

  return {
    emailList: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export { useEmailList };
