import { Menu, MenuButton, MenuList, MenuItem, Text } from "@chakra-ui/react";

function EmailMenus(props) {
  const classLevel = props.classlevel;
  const LinkText = ({ children, color, ...props }) => {
    const colorValue = color;
    return (
      <Text
        as="span"
        fontSize={["lg", "lg", "md"]}
        fontWeight="bold"
        color={colorValue}
        {...props}
      >
        {children}
      </Text>
    );
  };

  return (
    <Menu preventOverflow="true">
      <MenuButton color="white">
        {" "}
        <LinkText color={props.color}>Email</LinkText>
      </MenuButton>
      <MenuList>
        {classLevel === 1 && (
          <MenuItem
            as="a"
            href="/email"
            onClick={props.onClick}
            style={{
              paddingLeft: 20,
              textDecoration: "none",
              fontSize: "10",
              color: "inherit",
              "&:hover": { textDecoration: "none", color: "inherit" },
            }}
          >
            <LinkText>All</LinkText>
          </MenuItem>
        )}
        <MenuItem
          as="a"
          href="/inst-user"
          onClick={props.onClick}
          style={{
            paddingLeft: 20,
            textDecoration: "none",
            fontSize: "5",
            color: "inherit",
            "&:hover": { textDecoration: "none", color: "inherit" },
          }}
        >
          <LinkText>Instrument-List</LinkText>
        </MenuItem>
        {(classLevel === 1 || classLevel === 7) && (
          <MenuItem
            as="a"
            href="/email-inst-info"
            onClick={props.onClick}
            style={{
              paddingLeft: 20,
              textDecoration: "none",
              fontSize: "10",
              color: "inherit",
              "&:hover": { textDecoration: "none", color: "inherit" },
            }}
          >
            <LinkText>All-Instrument Status</LinkText>
          </MenuItem>
        )}
        {(classLevel === 1 || classLevel === 7) && (
          <MenuItem
            as="a"
            href="/email-annual-status"
            onClick={props.onClick}
            style={{
              paddingLeft: 20,
              textDecoration: "none",
              fontSize: "10",
              color: "inherit",
              "&:hover": { textDecoration: "none", color: "inherit" },
            }}
          >
            <LinkText> Annual Status Update</LinkText>
          </MenuItem>
        )}
        {(classLevel === 1 || classLevel === 7) && (
          <MenuItem
            as="a"
            href="/email-pending-user"
            onClick={props.onClick}
            style={{
              paddingLeft: 20,
              textDecoration: "none",
              fontSize: "10",
              color: "inherit",
              "&:hover": { textDecoration: "none", color: "inherit" },
            }}
          >
            <LinkText>Pending Users</LinkText>
          </MenuItem>
        )}
        {classLevel === 1 && (
          <MenuItem
            as="a"
            href="/account-verify"
            onClick={props.onClick}
            style={{
              paddingLeft: 20,
              textDecoration: "none",
              fontSize: "10",
              color: "inherit",
              "&:hover": { textDecoration: "none", color: "inherit" },
            }}
          >
            <LinkText>Users Acc No. Verify</LinkText>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}
export default EmailMenus;
