import useSWR from "swr";

const useUser = () => {
  const url = `${process.env.REACT_APP_API_URL}/user/`;

  const { data, error } = useSWR(url);

  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
  };
};

const useUserByAdvisor = (advisor) => {
  const params = new URLSearchParams();
  params.append("advisor", advisor);

  const url = `${process.env.REACT_APP_API_URL}/user?${params.toString()}`;
  console.log(url);

  const { data, error } = useSWR(url);

  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
  };
};

const useUserBySSO = () => {
  const url = `${process.env.REACT_APP_API_URL}/user/mygroups/info/`;
  console.log(url);

  const { data, error } = useSWR(url);
  console.log(data);
  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export { useUser, useUserByAdvisor, useUserBySSO };
