import CryptoJS from "crypto-js";

const encryptData = (data) => {
  const secretKey = "gDUBYldcSgHXeuyh";
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  return encryptedData;
};

const decryptData = (encryptedData) => {
  const secretKey = "gDUBYldcSgHXeuyh";
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

const auth = () => {
  const storedUserInfo = localStorage.getItem("userInfo");
  if (storedUserInfo) {
    const decryptedUserInfo = decryptData(storedUserInfo);
    console.log(decryptedUserInfo);
    if (decryptedUserInfo && decryptedUserInfo.expiration > Date.now()) {
      return decryptedUserInfo;
    } else {
      // User details have expired
      localStorage.removeItem("userInfo");
      alert("User session has expired. Please log in again.");
      // Redirect the user to the login page or perform any other necessary action
      window.location.href = `/login`;
    }
  } else {
    alert("Please login to view this page.");
    // Redirect the user to the login page or perform any other necessary action
    window.location.href = `/login`;
  }
};

export { encryptData, decryptData, auth };
