import { Heading, Box, Image, Link, Stack, Text } from "@chakra-ui/react";

const CNIAddress = () => {
  return (
    <>
      <Stack direction="column">
        <Heading
          as="h1"
          fontSize={["17px", "17px", "15px", "24px"]}
          color="black"
          alignSelf="center"
        >
          Core Center of Excellence in Nano Imaging
        </Heading>
        <Stack direction="row">
          <Link href="https://usc.edu" isExternal>
            <Image
              src={process.env.PUBLIC_URL + "/images/usc-shield-logo.png"}
              alt="University of Southern California"
              objectFit="contain"
              width={["100px", "100px", "100px", "200px"]}
              height={["100px", "100px", "100px", "200px"]}
            />
          </Link>
          <Box>
            <Text
              fontSize={["13px", "13px", "13px", "20px"]}
              color="black"
              align="left"
              pt={[0, 0, 3, 3]}
            >
              University of Southern California
              <br />
              1002 West Childs Way, MCB Building
              <br />
              Los Angeles, CA 90089-3502
              <br />
              cni.core at usc.edu
              <br />
              (213) 740-1990
            </Text>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default CNIAddress;
