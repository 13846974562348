import React, { useState, useEffect } from "react";
import { IconButton, Center } from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";

const BackToTopButton = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowBackToTop(scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const backToTopButtonStyle = {
    position: "fixed",
    bottom: "10%",
    right: "0%",
  };

  return (
    <>
      {showBackToTop && (
        <Center>
          <IconButton
            colorScheme="teal"
            variant="outline"
            onClick={handleBackToTop}
            mt={4}
            style={backToTopButtonStyle}
            icon={<ChevronUpIcon boxSize={8} />}
            aria-label="Back to Top"
          />
        </Center>
      )}
    </>
  );
};

export default BackToTopButton;
