import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  Text,
} from "@chakra-ui/react";

import { useState } from "react";

function StaffManageMenus(props) {
  const [isCalendarsOpen, setIsCalendarsOpen] = useState(false);
  const [isInstrumentsOpen, setIsInstrumentsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isUsersOpen, setIsUserOpen] = useState(false);
  const [isRequestsOpen, setIsRequestsOpen] = useState(false);
  const [isPIcustomersOpen, setIsPIcustomersOpen] = useState(false);
  const [isProjectsOpen, setIsProjectsOpen] = useState(false);
  const [isPublicationsOpen, setIsPublicationsOpen] = useState(false);

  const LinkText = ({ children, color, ...props }) => {
    const colorValue = color;
    return (
      <Text
        as="span"
        fontSize={["lg", "lg", "md"]}
        fontWeight="bold"
        color={colorValue}
        {...props}
      >
        {children}
      </Text>
    );
  };

  const handleRequestsToggle = () => {
    setIsRequestsOpen(!isRequestsOpen);
  };

  const handleCalendarToggle = () => {
    setIsCalendarsOpen(true);
    setIsInstrumentsOpen(false);
    setIsUserOpen(false);
    setIsPublicationsOpen(false);
  };

  const handleManageClick = () => {
    setIsCalendarsOpen(false);
    setIsInstrumentsOpen(false);
    setIsUserOpen(false);
    setIsPIcustomersOpen(false);
    setIsPublicationsOpen(false);
  };
  const handleInstrumentToggle = () => {
    setIsInstrumentsOpen(!isInstrumentsOpen);
    setIsCalendarsOpen(false);
    setIsUserOpen(false);
    setIsPIcustomersOpen(false);
    setIsPublicationsOpen(false);
  };
  const handleServiceToggle = () => {
    setIsServicesOpen(!isServicesOpen);
    setIsPIcustomersOpen(false);
    setIsPublicationsOpen(false);
  };

  const handleUserToggle = () => {
    setIsUserOpen(!isUsersOpen);
    setIsCalendarsOpen(false);
    setIsPIcustomersOpen(false);
    setIsPublicationsOpen(false);
  };
  const handlePIcustomersToggle = () => {
    setIsPIcustomersOpen(!isPIcustomersOpen);
    setIsCalendarsOpen(false);
    setIsInstrumentsOpen(false);
    setIsUserOpen(false);
    setIsPublicationsOpen(false);
  };

  const handleProjectsToggle = () => {
    setIsProjectsOpen(!isProjectsOpen);
    setIsPublicationsOpen(false);
  };

  const handlePublicationsToggle = () => {
    setIsPublicationsOpen(!isPublicationsOpen);
  };

  return (
    <Menu preventOverflow="true">
      <MenuButton color="white" onClick={handleManageClick}>
        {" "}
        <LinkText color={props.color}>Manage</LinkText>
      </MenuButton>
      <MenuList>
        <MenuGroup
          title="PI/Customers"
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          placement="rtl"
          onClick={handlePIcustomersToggle}
        >
          {isPIcustomersOpen && (
            <>
              <MenuItem
                as="a"
                href="/manage/manage-current-customers"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Current
              </MenuItem>

              <MenuItem
                as="a"
                href="/manage/manage-archive-customers"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Archived
              </MenuItem>
            </>
          )}
        </MenuGroup>

        <MenuGroup
          title="Calendar"
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          placement="rtl"
          onClick={handleCalendarToggle}
        >
          {isCalendarsOpen && (
            <>
              <MenuItem
                as="a"
                href="/under-construction"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Manage Cancellation
              </MenuItem>

              <MenuItem
                as="a"
                href="/under-construction"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Manage Reservation
              </MenuItem>
            </>
          )}
        </MenuGroup>

        <MenuGroup
          title="Instruments"
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          placement="rtl"
          onClick={handleInstrumentToggle}
        >
          {isInstrumentsOpen && (
            <>
              <MenuItem
                as="a"
                href="/instruments/instruments"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Current
              </MenuItem>

              <MenuGroup
                title="Service Reports"
                direction="rtl"
                onClick={handleServiceToggle}
              >
                {isServicesOpen && (
                  <>
                    <MenuItem
                      as="a"
                      href="/instruments/add-service-reports"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      Add Report
                    </MenuItem>
                    <MenuItem
                      as="a"
                      href="/instruments/view-service-reports"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      View Report
                    </MenuItem>
                  </>
                )}
              </MenuGroup>
            </>
          )}
        </MenuGroup>

        <MenuGroup
          title="Users"
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          placement="rtl"
          onClick={handleUserToggle}
        >
          {isUsersOpen && (
            <>
              <MenuItem
                as="a"
                href="/manage/manage-current-users"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Current
              </MenuItem>
              <MenuItem
                as="a"
                href="/manage/manage-archived-users"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Archived
              </MenuItem>

              <MenuGroup
                title="Requests"
                direction="rtl"
                onClick={handleRequestsToggle}
              >
                {isRequestsOpen && (
                  <>
                    <MenuItem
                      as="a"
                      href="/manage/new-requests-users"
                      onClick={props.onClick}
                      style={{
                        paddingLeft: 35,
                        textDecoration: "none",
                        fontSize: "5",
                        color: "inherit",
                        "&:hover": { textDecoration: "none", color: "inherit" },
                      }}
                    >
                      New Requests
                    </MenuItem>
                  </>
                )}
              </MenuGroup>

              <MenuItem
                as="a"
                href="/manage/add-documents"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "10",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Upload Documents
              </MenuItem>
            </>
          )}
        </MenuGroup>

        <MenuGroup
          title="Projects"
          placement="rtl"
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          onClick={handleProjectsToggle}
        >
          {isProjectsOpen && (
            <>
              <MenuItem
                as="a"
                href="/projects/cryo-em-access-request"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Cryo EM Submission
              </MenuItem>
              {props.Customer &&
                props.CryoCommitteeList &&
                props.Customer.email &&
                props.CryoCommitteeList.includes(props.Customer.email) && (
                  <MenuItem
                    as="a"
                    href="/projects/view-submission-cryo-em"
                    onClick={props.onClick}
                    style={{
                      paddingLeft: 20,
                      textDecoration: "none",
                      fontSize: "5",
                      color: "inherit",
                      "&:hover": { textDecoration: "none", color: "inherit" },
                    }}
                  >
                    View Cryo EM Submission
                  </MenuItem>
                )}
            </>
          )}
        </MenuGroup>

        <MenuGroup
          title="Publications"
          placement="rtl"
          fontWeight="bold"
          fontSize={["lg", "lg", "md"]}
          onClick={handlePublicationsToggle}
        >
          {isPublicationsOpen && (
            <>
              <MenuItem
                as="a"
                href="/publications/manage-publication"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Manage Publications
              </MenuItem>

              <MenuItem
                as="a"
                href="/publications/add-publication"
                onClick={props.onClick}
                style={{
                  paddingLeft: 20,
                  textDecoration: "none",
                  fontSize: "5",
                  color: "inherit",
                  "&:hover": { textDecoration: "none", color: "inherit" },
                }}
              >
                Add Publications
              </MenuItem>
            </>
          )}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
export default StaffManageMenus;
