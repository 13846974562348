import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    grey: "#e7e6e4",
    white: "#fff",
    profile_grey: "#CFCFCD",
    off_grey: "#ebebe9",
    blue: "#bee3f8",
    yellow: "#FEFCBF",
    green: "#14b305",
    red: "#e80c0c",
  },
});

export default theme;
