import { AllRoutes } from "./all-routes";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { SWRConfig } from "swr";
import fetcher from "./utils/fetcher";
import ScrollToTop from "./components/scroll-to-top";
import BackToTopButton from "./components/back-to-top-button";

function App() {
  return (
    <>
      <ChakraProvider>
        <CSSReset />
        <SWRConfig
          value={{
            fetcher,
          }}
        >
          <BackToTopButton></BackToTopButton>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}
          >
            <BrowserRouter>
              <ScrollToTop />
              <AllRoutes />
            </BrowserRouter>
          </GoogleReCaptchaProvider>
        </SWRConfig>
      </ChakraProvider>
    </>
  );
}

export default App;
